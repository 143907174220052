function toggleHamburger() {
  $("#nav-toggler").click(function () {
    $(".hamburger").toggleClass("is-active");
    $(".main-nav-container").toggleClass("nav--open");
    $("body").toggleClass("nav-open-body-overlay");
    return false;
  });
}
export { toggleHamburger };

function navCloseOnClick() {
  window.addEventListener("click", (event) => {
    let body = document.querySelector(".nav-open-body-overlay");
    let navToggler = document.querySelector("#nav-toggler");
    if (event.target == body) {
      navToggler.click();
    }
  });
}
export { navCloseOnClick };

function navMoveOnScroll() {
  if ($("body").hasClass("nav-open-body-overlay")) {
    $("#nav-toggler").click();
  }
}

export { navMoveOnScroll };

function toggleChildren() {
  $(".dropdown-toggle").click(function () {
    $(".dropdown-toggle").not(this).removeClass("dropdown-toggle__open");
    $(this).toggleClass("dropdown-toggle__open");
    $(".nav-parent-toggle").not(this).removeClass("nav-parent-toggle__open");
    $(this).toggleClass("nav-parent-toggle__open");
  });
  $(".nav-parent-toggle").click(function () {
    $(".nav-parent-toggle").not(this).removeClass("nav-parent-toggle__open");
    $(this).toggleClass("nav-parent-toggle__open");
  });
}
export { toggleChildren };

import jQueryBridget from "jquery-bridget";
import Isotope from "isotope-layout";
jQueryBridget("isotope", Isotope, $);

function employeeIsotopeSorter() {
  let $grid = $(".employee-row").isotope({});

  $(".employee-buttons-row").on("click",".employee-filter-button", function () {
      let filterValue = $(this).attr("data-filter");

      if (filterValue == "*") {
        $grid.isotope({
          filter: filterValue,
        });
      }
      if (filterValue != "*") {
        $grid.isotope({
          filter: "." + filterValue,
        });
      }
    }
  );
}

export { employeeIsotopeSorter };

function employeeCategoryToggle() {
  $(".employee-filter-button").click(function () {
    $(".employee-filter-button-active").removeClass("employee-filter-button-active");
    $(this).toggleClass("employee-filter-button-active");
  });
}

export { employeeCategoryToggle };

function smoothScroll() {
  const sections = document.querySelectorAll(".block .global-wrapper");
  const navLi = document.querySelectorAll("nav .navbar-inner ul li");

 navLi.forEach((li) => {
  li.classList.remove("active");
  });


  window.onscroll = () => {
    var current = "";

    sections.forEach((section) => {
      const sectionTop = section.offsetTop;
      if (pageYOffset >= sectionTop - 100) {
        current = section.getAttribute("id"); }
    });

    navLi.forEach((li) => {
      li.classList.remove("active");
      if (li.classList.contains(current)) {
        li.classList.add("active");
      }
    });
  };
 }

export { smoothScroll };
